import { Link } from "react-router-dom";
import { CONTENT_WIDTH, DEFAULT_ORANGE_CODE } from '../../utils/constants';
import VariableValueExtractor from "../utils/VariableValueExtractor";
const Read = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

     // XXX: "73a1b176-a04b-4188-8a2f-31be7bcfed34"
     if (variables?.variablesValues) {
        variables = variables?.variablesValues;
    }


    const findLink = (type) => {
        if(!variables) return null;
        var keys = Object.keys(variables);
        for(let key of keys){
            if(variables[key].type === type){
                return variables[key].value;
            }
        }

        return null;
    }

    let linkId = findLink('delimitationPage');
   
    if(!linkId)
    {
        linkId = findLink('page');
        if(!linkId)console.error("Link id not found");
    }

    var textId = template.render[0]?.children?.[0]?.uuid;

    if(!textId)
    {
        console.error("Link id not found");
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-0" style={{ width: !fullWidth ? CONTENT_WIDTH : null, flex: '0 0 auto', textWrap:'nowrap' }}>
            <Link to={linkId ? `/${deckId}/${linkId}` : '#'} style={{color:DEFAULT_ORANGE_CODE}}>
                <VariableValueExtractor
                    variableId={textId}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </Link>
        </div>
    )
}

export default Read;