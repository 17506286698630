import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findModuleVariables } from '../../utils/variable-extractor';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";


const IfYouHaveTheXItemCardSandbox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId,
}) => {

    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }
   
    let containerRender = template?.render[0];
    let firstModule = containerRender?.children[0]?.uuid
    let secondModule = containerRender?.children[1]?.variable

    const masterContainerStyle = {
        ...getContainerStyle(containerRender?.uuid, template, cards, card, variables, templateData, null),
        padding: "7px",
        ...(card._id === "653c0e20b7a06f9fe85be8bc" && { textAlign: "left" }),
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 w-full" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div>
                <VariableValueExtractor variableId={firstModule}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>

            {findModuleVariables(secondModule, variables).map((variable, index) => {
                return (
                    <ModuleSelector
                        key={index}
                        variable={variable}
                        card={card}
                        cards={cards}
                        deckId={deckId}
                        templateData={templateData}
                        fullWidth={true}
                    />
                )
            })}
        </div>
    )
}

export default IfYouHaveTheXItemCardSandbox;