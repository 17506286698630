import "./_templates.scss";
import {
  findVariableOrDefaultToTempateValue,
  findModuleVariables,
  findNestedModuleVariables,
  getContainerStyle,
} from "../../utils/variable-extractor";
import { BLOB_URL, DEFAULT_ORANGE_CODE, V_NUMBER } from "../../utils/constants";
import ModuleSelector from "../modules/_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";


const EventSpecialChapter = ({
  currentCard,
  template,
  cards,
  deckId,
  templateData,
  ctaId,
}) => {
  // EVENT : "c85ab9c0-8210-4260-bf6d-98981a8c3c7c"
  // EVENT Page Number : "bce700ec-ee20-4354-8521-78b112b4dcd2"


  const parentId = currentCard._id;

  const backgroundImage = BLOB_URL + findVariableOrDefaultToTempateValue("d6afd089-baab-4964-af31-d475b4d1fa48",
      template,
      cards,
      currentCard,
      currentCard.variablesValues,
      templateData
    ).value;

  const masterContainerStyle = { 
    ...getContainerStyle( "64fb38de590095491a929551", template, cards, currentCard, currentCard.variablesValues, templateData, null ),
  };

  return (
    <div className="w-full h-full bg-center bg-cover overflow-y-auto pb-5" style={{ backgroundImage: `url('${backgroundImage}')`,masterContainerStyle }}>
      <div className="top-section flex flex-col">
        <div className="flex">
          <div className="right w-full flex items-center justify-center mt-10">
            <div className="flex flex-col items-center w-full px-4">
              <img src={ BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank+Line.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "250px" }} alt="ank-line"/>
              <div className="flex">
                <VariableValueExtractor variableId={"ed9f7f74-2d11-4a8b-a4f9-c0cbda02aa81"}
                    template={template}
                    cards={cards}
                    card={currentCard}
                    variables={currentCard.variablesValues}
                    templateData={templateData}
                    renderId={null}
                    isLink={null}
                    ctaId={ctaId}
                    parentId={parentId}/>
                <div className="space" style={{ width: "5px" }}></div>
                <VariableValueExtractor variableId={"c4b124ee-630d-4e54-ab20-a29b182f25b7"}
                    template={template}
                    cards={cards}
                    card={currentCard}
                    variables={currentCard.variablesValues}
                    templateData={templateData}
                    renderId={null}
                    isLink={null}  
                    ctaId={ctaId}
                    parentId={parentId}/>
              </div>
              <img
                src={ BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Line+Tips.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "250px" }} alt="ank-line"/>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center flex-col mt-2">
          {findModuleVariables( "42dd4da9-a477-46b7-bade-6426250db73d", currentCard ).map((variable, index) => {
            return (
              <ModuleSelector
                key={index}
                variable={variable}
                card={currentCard}
                cards={cards}
                deckId={deckId}
                templateData={templateData}
              />
            );
          })}
        </div>
      </div>
      <div className="relative line-section w-full justify-end mt-10" style={{ backgroundColor: DEFAULT_ORANGE_CODE, height: "1.5px" }}>
        <img className="absolute w-2" style={{ left: "calc(50% - 4px)", top: "-0.6rem" }} src={ BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank_Line_Gold.webp" + "?v=" + V_NUMBER} alt="ank"/>
      </div>
      <div className="bottom-section">
        <div className="flex flex-col items-center mt-2">
            {findNestedModuleVariables( "0cf0533e-0a9f-419c-81fc-6d18c5777072",currentCard.variablesValues).map((variable, index) => {
            return (
              <ModuleSelector
                key={index}
                variable={variable}
                card={currentCard}
                cards={cards}
                deckId={deckId}
                templateData={templateData}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default EventSpecialChapter;
