import { useContext } from "react";
import TranslationsContext from "../../stores/translationsContext";
import { useTranslation } from "react-i18next";
import { getDelimitationPageNumber, getPageName, getPageNumber, getStyle, getTokensName, getTokensNumber, renderTextElement } from "../../utils/frogs-text-generator";

export default function VariableValueExtractor({
    variableId,
    template,
    cards,
    card,
    variables,
    templateData,
    renderId,
    isLink,
    noStyle,
    parentId,
    ctaId
}) {
    let cardTranslation = null;
    const { translationsCards, translationsCtas, translationsEnums } = useContext(TranslationsContext);
    const { i18n } = useTranslation();


    const findTextRenderObject = (uuid, parent) => {
        if (parent) {
            for (let item of parent) {
                if (item.uuid === uuid) {
                    return item;
                }

                if (item.children) {
                    const result = findTextRenderObject(uuid, item.children);
                    if (result !== null) {
                        return result;
                    }
                }
            }
        }

        return null;
    };

    const findNestedVariable = (id, variables) => {
        if (!variables) return null;
        if (variables?.[id]) return variables?.[id];

        if (variables.length) {
            for (let variable of variables) {
                var value = findNestedVariable(id, variable.variablesValues);
                if (value) return value;
            }
        }

        return null;
    }

    const findVariableOrDefaultToTempateValue = (id, template, cards, card, variables, templateData, renderId, isLink, noStyle) => {
        if (!card) return null;

        let translationCta = null;

        let value = findNestedVariable(id, variables);

        if (!value) {
            value = findTextRenderObject(id, template.render);
        }

        if (!cards) return value;

        if (renderId && value) {
            value.style = findTextRenderObject(renderId, template.render)?.style;
        }

        let textValue = "";
        if (false && i18n.language === "fr") {
            if (ctaId) {
                // translationCta = translationsCtas.find(_ => _.CTA === ctaId);
                // if(translationCta){
                //     let frValue = translationCta.values.find(_ => _.uuid === id);
                //     if (frValue) {
                //         textValue = frValue.text;
                //     }
                // }   
            }

            // cardTranslation = translationsCards.find(_ => _.card === card._id);
            // if (cardTranslation && parentId) {
            //     let frValue = cardTranslation.variablesValues.find(_ => _.uuid === id && _.parentId === parentId);
            //     if(frValue) textValue = frValue.text;
            // }
        }

        if (value && value.type === "string") {
            if(textValue) value.value = textValue;
            return (
                <p style={!noStyle ? getStyle(value.style, templateData) : null}>
                    {value.value}
                </p>
            )
        }else{
            if(textValue) value.text = textValue;
        }



        if (value && value.text) {
            value = generateText(value.text, value.style, cards, card._id, variables, templateData, isLink, noStyle);
        }

        if (value && value.value && value.type === 'text') {
            value = generateText(value.value, value.style, cards, card._id, variables, templateData, isLink, noStyle);
        }


        return value;
    }

    const renderMentionText = (paragraphElement, index, cards, currentCardId, variables, templateData, isLink, noStyle) => {
        //TODO: get mention value from variable
        let mentionValue = `@${paragraphElement.attrs.label}`;
    
        if (paragraphElement.attrs?.type === "currentPage") {
            mentionValue = getPageNumber(currentCardId, cards);
        }
    
        if (paragraphElement.attrs?.type === "currentPageName" && currentCardId) {
            mentionValue = getPageName(currentCardId, mentionValue, cards);
        }
    
        if (paragraphElement.attrs?.type === "currentDelimitationPage") {
            mentionValue = getDelimitationPageNumber(currentCardId, cards);
        }
    
        if (paragraphElement.attrs?.type === "number") {
            mentionValue = "0";
        }
    
        const enumerators = templateData.settings.enumerators;
        const variable = findNestedVariable(paragraphElement.attrs.id, variables);
    
        if (variable) {
            const valueType = variable?.type;
            const value = variable?.value;
    
            switch (valueType) {
                case "string":
                    mentionValue = value;
                    break;
    
                case "number":
                    mentionValue = value;
                    break;
    
                case "enumerator":
                    let enumerator = null;
                    // if(i18n.language === "fr"){
                    //     enumerator = translationsEnums.find(
                    //         (enumerator) => enumerator.enums === variable?.enumeratorId
                    //     );
                    // }

                    if(!enumerator){
                        enumerator = enumerators.find(
                            (enumerator) => enumerator._id === variable?.enumeratorId
                        );
                    }
                    
                    if (enumerator) {
                        mentionValue = enumerator.values.find(
                            (value) => value.uuid === variable?.value
                        )?.name;
                    } else {
                        mentionValue = "none";
                    }
                    break;
    
                case "token":
                    if (paragraphElement.attrs?.type === "tokenNumber") {
                        mentionValue = getTokensNumber(value, templateData);
                    }
    
                    if (paragraphElement.attrs?.type === "tokenName") {
                        mentionValue = getTokensName(variable, mentionValue, templateData);
                    }
                    break;
    
                case "page":
                    if (paragraphElement.attrs?.type === "pageNumber") {
                        mentionValue = getPageNumber(value, cards);
                    }
    
                    if (paragraphElement.attrs?.type === "pageName") {
                        mentionValue = getPageName(value, mentionValue, cards);
                    }
                    break;
    
                case "delimitationPage":
                    mentionValue = getDelimitationPageNumber(value, cards);
                    break;
    
                default:
                    break;
            }
        }
    
        let elementStyle = getStyle(paragraphElement.attrs?.style, templateData);
        return (
            <span
                key={paragraphElement.attrs?.id + index}
                style={!noStyle ? { ...elementStyle } : null}
                className={isLink && (elementStyle?.color === "rgb(187, 138, 67)" || elementStyle?.color === "#bb8a43") ? 'underline' : ''}
            >
                {mentionValue}
            </span>
        );
    };
    
    const renderParagraphElement = (paragraphElement, index, cards, currentCardId, variables, templateData, isLink, noStyle) => {
        switch (paragraphElement.type) {
            case "text":
                return renderTextElement(paragraphElement, index, templateData, isLink, noStyle);
            case "mention":
                return renderMentionText(paragraphElement, index, cards, currentCardId, variables, templateData, isLink, noStyle);
            default:
                return null;
        }
    };
    
    const renderParagraph = (element, index, renderStyle, cards, currentCardId, variables, templateData, isLink, noStyle) => {
        if (element.content === undefined || element.content.length === 0) {
            return <br key={index} />;
        }
    
        const renderText = element.content.map((element, i) =>
            renderParagraphElement(element, i, cards, currentCardId, variables, templateData, isLink, noStyle)
        );
    
        let elementStyle = getStyle(renderStyle, templateData);
    
        return (
            <p
                key={index}
                style={!noStyle ? {
                    ...getStyle(renderStyle, templateData)
                } : null}
                className={isLink && (elementStyle?.color === "rgb(187, 138, 67)" || elementStyle?.color === "#bb8a43") ? 'underline' : ''}
            >
                {renderText}
            </p>
        );
    };
    
    const generateText = (jsonText, renderStyle, cards, currentCardId, variables, templateData, isLink, noStyle) => {
        if (!jsonText?.content) {
            return null;
        }
    
        const content = jsonText.content.map((element, index) =>
            element.type === "paragraph" ? renderParagraph(element, index, renderStyle, cards, currentCardId, variables, templateData, isLink, noStyle) : null
        );
    
        return content;
    };

    return findVariableOrDefaultToTempateValue(variableId, template, cards, card, variables, templateData, renderId, isLink, noStyle, cardTranslation, translationsCards, i18n.language)
}