import './_templates.scss'
import { findVariableOrDefaultToTempateValue, findModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import { BLOB_URL, DEFAULT_ORANGE_CODE, V_NUMBER } from '../../utils/constants';
import ModuleSelector from '../modules/_module-selector';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const EventEndLeft = ({
    currentCard,
    template,
    cards,
    deckId,
    templateData,
    ctaId,

}) => {

    const parentId = currentCard._id;

    const masterContainerStyle = {
        ...getContainerStyle("ead2559c-ad2f-4c33-948e-ea4687653a19", template, cards, currentCard, currentCard.variablesValues, templateData, null)
    }

    return (
        <div className="w-full h-full flex flex-col" style={masterContainerStyle}>
            <div className='top-section flex flex-col grow'>
                <div className='flex'>
                    <div className='right w-full flex items-center justify-center mt-10'>
                        <div className="flex flex-col items-center w-full px-4">
                            <img src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank+Line.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "250px" }} alt="ank-line" />

                            <div className='flex'>
                                <VariableValueExtractor variableId={"53eba496-0456-4daf-a2c6-98421584e3b3"}
                                    template={template}
                                    cards={cards}
                                    card={currentCard}
                                    variables={currentCard.variablesValues}
                                    templateData={templateData}
                                    renderId={null}
                                    isLink={null}
                                    ctaId={ctaId}
                                    parentId={parentId}/>
    
                                <div className='space' style={{ width: '5px' }}></div>
                                
                                <VariableValueExtractor variableId={"fffe2d6c-c3af-410c-a3bc-06fcc17db197"}
                                    template={template}
                                    cards={cards}
                                    card={currentCard}
                                    variables={currentCard.variablesValues}
                                    templateData={templateData}
                                    renderId={null}
                                    isLink={null}
                                    ctaId={ctaId}
                                    parentId={parentId}/>

                            </div>

                            <img src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Line+Tips.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "250px" }} alt="ank-line" />
                        </div>
                    </div>
                </div>
                <div className='flex items-center justify-center flex-col mt-2'>
                    {findModuleVariables("42dd4da9-a477-46b7-bade-6426250db73d", currentCard)?.map((variable, index) => {
                        return (
                            <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            />
                            )
                        })}
                </div>
            </div>

            <div className='relative line-section w-full justify-end mt-10' style={{ backgroundColor: DEFAULT_ORANGE_CODE, height: '3px' }}>
                <img className='absolute w-2' style={{ left: 'calc(50% - 4px)', top: '-0.6rem' }} src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank_Line_Gold.webp" + "?v=" + V_NUMBER} alt="ank" />
            </div>

            <div className='bottom-section'>
                <div className='flex flex-col items-center justify-center background-image' style={{
                    height: '200px', backgroundImage: `url(${BLOB_URL}${findVariableOrDefaultToTempateValue("24fe01b4-2b97-4b15-aba4-08c59bd6bf0d", template, cards, currentCard, currentCard.variablesValues, templateData).image + "?v=" + V_NUMBER
                        })`
                }}>
                    <div className='background-overlay flex flex-col items-center justify-center text-center'>
                        <div>
                            <VariableValueExtractor variableId={"c4c78413-34b6-4eb6-ad3c-8fd02a280237"}
                                template={template}
                                cards={cards}
                                card={currentCard}
                                variables={currentCard.variablesValues}
                                templateData={templateData}
                                renderId={null}
                                isLink={null} 
                                ctaId={ctaId}
                                parentId={parentId}/>
                            <div className='relative line-section w-full justify-end mt-1 mb-3' style={{ backgroundColor: DEFAULT_ORANGE_CODE, height: '2px' }}>
                                <img className='absolute w-2' style={{ left: 'calc(50% - 7.5px)', top: 'rem', width: "15px" }} src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Tip_Line_Gold.webp" + "?v=" + V_NUMBER} alt="ank" />
                            </div>
                        </div>
                        <VariableValueExtractor variableId={"9ff01fd0-26db-4184-bff6-139e0fe4a7c4"}
                            template={template}
                            cards={cards}
                            card={currentCard}
                            variables={currentCard.variablesValues}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default EventEndLeft;