import './_templates.scss'
import { getContainerStyle, findModuleVariables, findNestedVariable} from '../../utils/variable-extractor';
import { BLOB_URL, V_NUMBER } from '../../utils/constants';
import ModuleSelector from '../modules/_module-selector';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const EventSetupWithNoBottomSpeciaChapterLeft = ({
    currentCard,
    template,
    templateData,
    cards,
    deckId,
    ctaId,
}) =>{

    const parentId = currentCard._id;

    var imageValue = template.render[0]?.children[0]?.children[1]?.children[1]?.children[0]?.image;

    const masterContainerStyle = {
        ...getContainerStyle("dd82a161-3494-4b95-a4f5-6eba63b14e1a", template, cards, currentCard, currentCard.variablesValues, templateData, null)
    }

    return (
        <div className="w-full h-full overflow-y-auto pb-5" style={masterContainerStyle}>
            <div className='top-section flex flex-col'>
                <div className='flex'>
                    <div className='right w-full flex items-center justify-center mt-10'>
                        <div className="flex flex-col items-center w-full px-4">
                            <img src={BLOB_URL +"upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank+Line.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "250px" }} alt="ank-line" />

                            <div className='flex'>
                                <div className='flex-none'>
                                    <VariableValueExtractor variableId={"a8504f62-c739-4ad1-a852-464013bc0bce"}
                                        template={template}
                                        cards={cards}
                                        card={currentCard}
                                        variables={currentCard.variablesValues}
                                        templateData={templateData}
                                        renderId={null}
                                        isLink={null}
                                        ctaId={ctaId}
                                        parentId={parentId}/>
                                </div>
                                <div className='space' style={{ width: '5px' }}></div>
                                    <VariableValueExtractor variableId={"3210d71f-3aa1-4cd7-a078-1a7bdeef8a04"}
                                        template={template}
                                        cards={cards}
                                        card={currentCard}
                                        variables={currentCard.variablesValues}
                                        templateData={templateData}
                                        renderId={null}
                                        isLink={null}  
                                        ctaId={ctaId}
                                        parentId={parentId}/>
                            </div>
                            <img src={BLOB_URL +"upload/images/64ed011e17b7622c4d323360/Booklets/icons/Line+Tips.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "250px" }} alt="ank-line" />
                        </div>
                    </div>
                </div>

                <div className='flex items-center justify-center flex-col mt-2'>
                    {findModuleVariables("42dd4da9-a477-46b7-bade-6426250db73d", currentCard).map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                            />
                        )
                    })}
                </div>

                
                    {imageValue ? (<div className='flex w-100 py-2 px-5'><img src={BLOB_URL + imageValue + "?v=" + V_NUMBER}  alt="image" /></div>) : (<></>)}
                
            </div>
        </div>
    )
}

export default EventSetupWithNoBottomSpeciaChapterLeft;