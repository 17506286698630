import { transform } from 'framer-motion';
import HexGrid from '../tools/HexGrid';
import { getContainerStyle } from '../../utils/variable-extractor';

const SetupImageHexGrid = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData
}) => {

    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }


    //decalage Hexgrid
    const getDynamicStyles = () => {
        let dynamicStyles = {};

        // Conditions spécifiques pour les styles
        if (
            card._id === "654be10e451d7e555c8d75cc" ||// Chap 10 E.6
            card._id === "6553933e451d7e555cb7dab6" ||//Chap 15 FD.12
            card._id === "66bcf1a739261b501d21e847" ||//Hollow1 E.19
            card._id === "663a4755b95fd0ec1c637103" ||  //Chap 8 E.41
            card._id === "653123c1779575d19d092812" ||//Chap 2 Machine Room back
            card._id === "65527b04451d7e555cb1d790" ||// chap 16 E.2
            card._id === "6557a7b758c548552e430b3c" ||//chap 19 E.12
            card._id === "65551b6b451d7e555cc46ce4" ||//chap 22 E.21
            card._id === "65551b94451d7e555cc47690" ||//chap22 E.38
            card._id === "65a16458c8178fd60488f685" ||//chap 30 E.32
            card._id === '66ea241939261b501dfe87d2' //chap 32 E.4

           ){
            dynamicStyles.marginLeft = "2rem";
        }

        if( card._id === "654547e7b7a06f9fe86dada2" ||// chap 8 IN.8
            card._id === "655393b7451d7e555cb7e177" ||// chap 15 E.4
            card._id === '6564f47253dea9575438da4e' ||// chap18 E31
            card._id === "6557a7da58c548552e43135d" ||//chap 19 E.25
            card._id === "655baf7858c548552e58afc7" ||//chap 26 E.61
            card._id === "655bca8958c548552e5bafbc" //chap 26 E.62
        ){ 
            dynamicStyles.marginTop = "4rem";
        }

        if (card._id === "65563fc258c548552e30d577") { //devalage Hexgrid Chap 18 D.12
            dynamicStyles.marginRight = "-2rem";
            dynamicStyles.marginLeft = "2rem";
            dynamicStyles.width = "89%";
        } else {
            dynamicStyles.width = "78%"; // Valeur par défaut
        }

        return dynamicStyles;
    };

    const containerStyle = {
        ...getContainerStyle("33fde691-96f6-41c6-be40-f072b3d60f6e", template, cards, card, variables, templateData, null),
        height: "auto",
        margin: "3rem 0 2rem",
        transform: "scale(1.2)",
        overflow:"hidden",
        ...getDynamicStyles(),
    };

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 paddingHexGrid" style={containerStyle}>
            <HexGrid 
                variables={variables}
                template={template}
                cards={cards}
                card={card}
                templateData={templateData}
                variable="54b9a0e5-42cd-458c-bb4a-1894558e54ed"
            />
        </div>
    );
}

export default SetupImageHexGrid;
