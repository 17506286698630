import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle } from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const TextBoxContainer = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    parentId,
    ctaId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let containerRender = template.render[0];
    let textRender = containerRender.children[0];

    const masterContainerStyle = {
        ...getContainerStyle(containerRender.uuid, template, cards, card, variables, templateData, null),
        paddingTop:"4px",
        paddingBottom:"4px",
        paddingLeft:"7px",
        paddingRight:"7px",    
    }


    return (
        <div name={`${template._id} - ${template.name}`} className="mt-2" style={{ 
            ...masterContainerStyle, 
            width: ctaId === "656a514589231f88c3f10e33" || //module chap 8 bas de page
                   ctaId === "65f06fb9cec4c40b6846f344" //module chap 9 bas de page
                ? "100%" 
                : (!fullWidth && ctaId != '672a624a39261b501d069b1e' 
                    ? CONTENT_WIDTH 
                    : null)  }}>
            <VariableValueExtractor variableId={textRender.uuid}
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                renderId={null}
                isLink 
                ctaId={ctaId}
                parentId={parentId}/>
        </div>
    )
}

export default TextBoxContainer;